// Fonts
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

@font-face {
   font-family: open-sans;
   src: url('/assets/fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
   font-family: open-sans-semibold;
   src: url('/assets/fonts/OpenSans-SemiBold.ttf') format('truetype');
}

@font-face {
   font-family: open-sans-bold;
   src: url('/assets/fonts/OpenSans-Bold.ttf') format('truetype');
   font-weight: 700;
   font-style: bold;
}

@font-face {
   font-family: baloo2;
   src: url('/assets/fonts/Baloo2-Regular.ttf') format('truetype');
}

@font-face {
   font-family: baloo2-semibold;
   src: url('/assets/fonts/Baloo2-SemiBold.ttf') format('truetype');
}

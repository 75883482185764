@import '~@angular/cdk/overlay-prebuilt.css';

.cdk-global-scrollblock {
   overflow-y: hidden;
   top: 0 !important;
}
.cdk-global-overlay-wrapper {
   overflow: auto;
   display: flex;
   align-items: center;
   justify-content: center;
}

.custom-modal {
   width: 100%;
   min-height: 307px;
   padding: 16px 24px 32px;
   background: #fff;
   overflow: auto;
   display: block;
   position: relative !important;
   cursor: default;
   bottom: 77px;
   @include respond-to('medium') {
      width: 465px;
      border-radius: 20px;
      padding: 16px 40px 32px;
   }
}

.background-modal {
   background: rgba(0, 0, 0, 0.8);
   cursor: default;
}


.confirmation{
   width: 399px;
   min-height: 270px;
   bottom: 36px;
}

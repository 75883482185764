// Fonts
$font-family-moedas: 'Open Sans';
$font-family-moedas-semi-bold: 'open-sans-semibold';

// Colors
$primary-color: #1251ff;
$primary-color-light: #3877ff;
$background-color: #f6f7f9;
$primary-text-color: #4b4b4b;
$secondary-on-color: #757575;
$secondary-color: #707070;
$background-on-color: #f8f8f8;
$background-input-search: #eaeaea;
$gray-color: #f2f2f2;
$border-color: #e3e3e3;
$readonly-bg: #eaecef;
$readonly-color: #ced4da;
$background-header: #f6f7f9;
$dark: #222222;
$dark-2: #161616;
$gray: #b6b6b6;
$gray-2: #808080;
$gray-medium: #a5a5a5;
$red: #de0d0d;
$dark-red: #aa2c2c;
$alert-red: #e15050;
$mid-red: #be7e85;
$border-dark: #c4c4c4;

$green: #55aa3c;
$medium-green: #318327;
$yellow: #fcde61;
$yellow-dark: #e0bc22;
$light-blue: #f0f5ff;

//alerts
$success-background: #d1e7dd;
$success-color: #0f5132;
$success-border: #badbcc;
$error-background: #f8d7da;
$error-color: #721c24;
$error-border: #f5c6cb;
$warn-background: #fff3cd;
$warn-color: #664d03;
$warn-border: #ffecb5;
// Text
$line-hight: 20px;
$line-hight-cto: 41px;

// Pressets
$max-width: 1200px;
$max-width-swiper-mobile: 414px;
$hight-header: 88px;
$width-logo: 180px;
$hight-footer: 320px;
$call-to-action-height: 444px;
$safe-zone-height: 336px;

// Btn Default
$btn-on-color: #f6f7f9;

@import './assets/styles/main';

// Material Styles

.mat-form-field-type-mat-date-range-input .mat-form-field-infix {
   display: contents;
   position: absolute;
}

.mat-form-field-wrapper {
   width: 257px;
}

.mat-datepicker-actions {
   gap: 8px;
}

.mat-datepicker-actions > .btn {
   padding: 14px 16px;
}

.mat-form-field {
   position: absolute !important;
   right: 0 !important;
}

// Retirada css deeppurple

.mat-snack-bar-container {
   color: inherit !important;
   background: inherit !important;
   box-shadow: inherit !important;
}